#contact {
  position: relative;
  background: url("../../assets/images/marbleBG50.webp");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
}

.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;
  
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .app__footer-cards {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 4em 2rem 2rem;
  
    .app__footer-card {
      min-width: 290px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  
      margin: 1rem 0;
      padding: 1rem;
      border-radius: 10px;
      background-color: #fef4f5;
  
      transition: all 0.3s ease-in-out;
  
      img {
        width: 40px;
        height: 40px;
        margin: 0 0.7rem;
      }
  
      p {
        font-weight: 500;
      }
      a {
        text-decoration: none;
        font-weight: 500;
      }
      &:hover {
        box-shadow: 0 0 25px #fef4f5;
      }
  
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  
  .app__footer-cards .app__footer-card {
    background-color: #f2f7fb;
  }
  
  .app__footer-cards .app__footer-card:hover {
    box-shadow: 0 0 25px #f2f7fb;
  }
  
  .app__footer-form {
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;
  
    div {
      width: 100%;
  
      margin: 0.75rem 0;
      border-radius: 10px;
      cursor: pointer;
      background-color: var(--primary-color);
  
      transition: all 0.3s ease-in-out;
  
      input,
      textarea {
        width: 100%;
        padding: 0.95rem;
        border: none;
        border-radius: 7px;
        background-color: var(--primary-color);
  
        font-family: var(--font-base);
        color: var(--secondary-color);
        outline: none;
      }
  
      textarea {
        height: 170px;
      }
  
      &:hover {
        box-shadow: 0 0 25px var(--primary-color);
      }
    }
  
    button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: var(--secondary-color);
  
      font-weight: 500;
      color: var(--white-color);
      outline: none;
      margin: 2rem 0 0 0;
      font-family: var(--font-base);
  
      transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      cursor: pointer;
  
      &:hover {
        background-color: #2430af;
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0;
    }
  }

  .app_footer-icon{
    margin-right: 1rem;
  }

  .contact__message {
    font-size: 2.75rem;
    font-weight: 800;
    text-align: center;
    color: var(--black-color);
    text-transform: capitalize;
    color: #2430af;
    span {
      color: var(--secondary-color);
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 4rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
  }

 input[type=submit] {
    background-color: #2430af;
    border: none;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    border-radius: 1rem;
    font-size: 1.4rem;
    margin: 4px 2px;
    cursor: pointer;
  }